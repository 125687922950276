/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { readContract, writeContract } from '@wagmi/core'
import '../App.css'
import VestingAbi from '../config/VestingAbi.json'
import "../styles/VestingContainer.css";
import ClipLoader from "react-spinners/ClipLoader";
import { waitForTransaction } from '@wagmi/core'
import { useWeb3Modal } from "@web3modal/react";
import { toast } from "react-hot-toast";
import HeaderLogo from "../icons/Frame 62.png";
import walletLogo from "../icons/wallet2.png";
import VestingLogo from "../icons/Vesting.png";

const App = () => {
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  let [loading, setLoading] = useState(false);
  let [confirming, setConfirming] = useState(false);
  let [VestingAddress, setVestingAddress] = useState("0x9096D8854db159c6B030F14AC04d701c38b25198")
  const [firstConnect, setFirstConnect] = useState(false);
  const { open } = useWeb3Modal();

  const [enableClaim, setEnableClaim] = useState(false);
  const [totalClaimableAmount, setTotalClaimableAmount] = useState(0);
  const [totalClaimedAmount, setTotalClaimedAmount] = useState(0);

  const [totalAllocationAmount, setTotalAllocationAmount] = useState(0);
  const [totalAvailableAmount, setTotalAvailableAmount] = useState(0);
  let [userDatas, setUserDatas] = useState();

  let [vestingDatas, setVestingDatas] = useState(0);
  let [tab, setTab] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  let [TGE, setTGETime] = useState(0);
  const onConnect = async () => {
    await open();
    setFirstConnect(true);
  };
  const { switchNetwork } = useSwitchNetwork()
  useEffect(() => {
    const switchChain = async () => {
      try {
        switchNetwork?.(56)
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true) {
      if (chain.id !== 56)
        switchChain();
    }
  }, [isConnected, chain?.id, switchNetwork])

  useEffect(() => {
    if (isConnected === true) {
      if (chain.id === 56) {
        setVestingAddress("0x9096D8854db159c6B030F14AC04d701c38b25198")
      }
    }
  }, [isConnected, chain?.id])

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload();
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true)
      reloadWindow();

  }, [isConnected, firstConnect])

  const onTokenClaim = async (data) => {
    try {
      setConfirming(true);
      let claim;
      claim = await writeContract({
        address: VestingAddress,
        abi: VestingAbi,
        functionName: 'claimAmountById',
        args: [data.toString()],
        account: address
      })
      const claimData = await waitForTransaction({
        hash: claim.hash
      })
      console.log('claimData', claimData)
      setTimeout(function () {
        setConfirming(false);
      }, 3000)

      toast.success(`Successfully ${userDatas[(data - 1).toString()][3]} tokens claimed`);
      const isEnabled = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'isEnableClaim' });
      setEnableClaim(isEnabled);
      let totalAllocationAmount = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'totalAllocationAmount' });
      totalAllocationAmount = ethers.utils.formatEther(totalAllocationAmount);
      setTotalAllocationAmount(totalAllocationAmount);
      let totalClaimedAmount = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'TotalClaimedAmount', args: [address] });
      totalClaimedAmount = ethers.utils.formatEther(totalClaimedAmount);
      setTotalClaimedAmount(totalClaimedAmount);
      let totalAvailableAmount = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'totalAvaliableAmount', args: [address] });
      totalAvailableAmount = ethers.utils.formatEther(totalAvailableAmount);
      setTotalAvailableAmount(totalAvailableAmount);
      const totalclaimableData = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'TotalClaimableAmount', args: [address] });
      let totalClaimableAmounts1 = ethers.utils.formatEther(totalclaimableData);
      setTotalClaimableAmount(totalClaimableAmounts1);
      let userData = [];
      for (let i = 1; i < 4; i++) {
        let allocationAmount = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'allocationAmount', args: [i] });
        allocationAmount = ethers.utils.formatEther(allocationAmount);
        let vestingData = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'vesting', args: [i] });
        let claimableAmountById = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'CalculateClaimAmountById', args: [address, i] });
        claimableAmountById = ethers.utils.formatEther(claimableAmountById);
        let userClaimData = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'userClaimData', args: [address, i] });
        let blockStamp = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'blockStamp' });
        let datas = [allocationAmount, ethers.utils.formatEther(userClaimData[3]), ethers.utils.formatEther(userClaimData[2]), claimableAmountById, Number(vestingData[1]), Number(blockStamp), ethers.utils.formatEther(userClaimData[1])];
        userData.push(datas);
      }
      setUserDatas(userData)

    } catch (err) {
      toast.error('There is a problem with your claim. Please try again later');
      setConfirming(false);
    }
  };

  const onChangeTab0 = () => {
    setTab(0)
  };

  const onChangeTab1 = () => {
    setTab(1)
  };

  useEffect(() => {
    const FetchGlobalData = async () => {
      try {
        setLoading(true);
        const TGE = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'TGETime' });
        setTGETime(TGE);
        const isEnabled = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'isEnableClaim' });
        setEnableClaim(isEnabled);
        let totalAllocationAmount = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'totalAllocationAmount' });
        totalAllocationAmount = ethers.utils.formatEther(totalAllocationAmount);
        setTotalAllocationAmount(totalAllocationAmount);
        let totalClaimedAmount = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'TotalClaimedAmount', args: [address] });
        totalClaimedAmount = ethers.utils.formatEther(totalClaimedAmount);
        setTotalClaimedAmount(totalClaimedAmount);
        let totalAvailableAmount = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'totalAvaliableAmount', args: [address] });
        totalAvailableAmount = ethers.utils.formatEther(totalAvailableAmount);
        setTotalAvailableAmount(totalAvailableAmount);
        const totalclaimableData = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'TotalClaimableAmount', args: [address] });
        let totalClaimableAmounts1 = ethers.utils.formatEther(totalclaimableData);
        setTotalClaimableAmount(totalClaimableAmounts1);
        let userData = [];
        for (let i = 1; i < 4; i++) {
          let allocationAmount = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'allocationAmount', args: [i] });
          allocationAmount = ethers.utils.formatEther(allocationAmount);
          let vestingData = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'vesting', args: [i] });
          let claimableAmountById = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'CalculateClaimAmountById', args: [address, i] });
          claimableAmountById = ethers.utils.formatEther(claimableAmountById);
          let userClaimData = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'userClaimData', args: [address, i] });
          let blockStamp = await readContract({ address: VestingAddress, abi: VestingAbi, functionName: 'blockStamp' });
          let datas = [allocationAmount, ethers.utils.formatEther(userClaimData[3]), ethers.utils.formatEther(userClaimData[2]), claimableAmountById, Number(vestingData[1]), Number(blockStamp), ethers.utils.formatEther(userClaimData[1])];
          userData.push(datas);
        }
        setUserDatas(userData)
        setLoading(false);
      } catch (e) {
        console.error(e)
        setLoading(false);
      }
    }
    if (address)
      FetchGlobalData();
  }, [address, VestingAddress])

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [timers, setTimers] = useState();
  const getTime = async () => {
    let timerDatas = [];
    for (let i = 0; i < 3; i++) {
      let datas;
      let time;
      if (userDatas === undefined) {
        if (vestingDatas === 0) {
          let nextVestingTime = "1725531330";
          setVestingDatas(Number(nextVestingTime));
          time = (Number(nextVestingTime) * 1000) - Date.now();
          if (Number(nextVestingTime) * 1000 < Date.now()) {
            time = 0;
          }
        } else {
          time = (Number(vestingDatas) * 1000) - Date.now();
          if (Number(vestingDatas) * 1000 < Date.now()) {
            time = 0;
          }
        }
      } else {
        time = (Number(userDatas[i][5]) * 1000) - Date.now();
      }
      datas = [Math.floor(time / (1000 * 60 * 60 * 24)), Math.floor((time / (1000 * 60 * 60)) % 24), Math.floor((time / 1000 / 60) % 60), Math.floor((time / 1000) % 60)]
      timerDatas.push(datas);
    }
    setTimers(timerDatas);
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(Number(TGE) * 1000), 1000);
    return () => clearInterval(interval);
  }, []);

  const SubContents1 = ({ i }) => {
    let title;
    if (i === 0) {
      title = 'Pre-Seed'
    } else if (i === 1) {
      title = 'Seed'
    } else if (i === 2) {
      title = 'Private'
    }
    return (
      <section className='HeaderContents2'>
        <section className='HeaderContent2'>
          <div className='HeaderContent2Box'>
            <div className='HeaderContent2TitleBox'>
              <div className='HeaderContent2Title'>
                <span>{title} Round</span>
              </div>
            </div>
            <section className='HeaderContentsBox'>
              {/* <section className='HeaderContent2Text'>
                <span>Allocation Amount</span>
                <p>{userDatas[i][0]}</p>
              </section> */}
              <section className='HeaderContent2Text'>
                <span>Claimed Amount</span>
                <p>{userDatas[i][1]}</p>
              </section>
              <section className='HeaderContent2Text'>
                <span>Available Amount</span>
                <p>{userDatas[i][6]}</p>
              </section>
            </section>
            <section className='HeaderContentsBox'>

              <section className='HeaderContent2Text'>
                <span>Claimable Amount</span>
                <p>{userDatas[i][3]}</p>
              </section>
            </section>
          </div>

          <div className='TimeBox'>
            <section className='HeaderContent2Text'>
              <span>Vesting Time Left</span>
            </section>
            <section className='TimeContents' id="clockdiv">
              <section className='TimeContent'>
                <span class="days">{timers[i][0]}</span>
                <p>Days</p>
              </section>
              <section className='TimeContent'>
                <span class="hours">{timers[i][1]}</span>
                <p>Hours</p>
              </section>
              <section className='TimeContent'>
                <span class="minutes">{timers[i][2]}</span>
                <p>Minutes</p>
              </section>
              <section className='TimeContent'>
                <span class="seconds">{timers[i][3]}</span>
                <p>Seconds</p>
              </section>
            </section>
            {confirming === false ?
              <>
                {isConnected && chain?.id === 56 ? <>
                  <button disabled={Number(userDatas[i][3]) > 0 ? false : true} onClick={() => onTokenClaim(i + 1)} className="VestingButton">Claim</button>
                </> :
                  <></>
                }
              </>
              :
              Number(userDatas[i][3]) > 0 ?
                <div className='claimBox'>
                  <p className='Text1'>Confirming...</p>
                  {/* <ClipLoader
                    color={'#afccc6'}
                    loading={confirming}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  /> */}
                </div>
                :
                <button disabled={true} className="VestingButton">Claim</button>
            }
          </div>
        </section>
      </section>
    )
  }

  const SubContents2 = ({ i }) => {
    let title;
    if (i === 0) {
      title = 'Pre-Seed'
    } else if (i === 1) {
      title = 'Seed'
    } else if (i === 2) {
      title = 'Private'
    }
    return (
      <section className='HeaderContents2'>
        <section className='HeaderContent2'>
          <div className='HeaderContent2Box'>
            <div className='HeaderContent2TitleBox'>
              <div className='HeaderContent2Title'>
                <span>{title} Round</span>
              </div>
            </div>
            <section className='HeaderContentsBox'>
              {/* <section className='HeaderContent2Text'>
                <span>Allocation Amount</span>
                <p>{userDatas[i][0]}</p>
              </section> */}
              <section className='HeaderContent2Text'>
                <span>Claimed Amount</span>
                <p>{userDatas[i][1]}</p>
              </section>
              <section className='HeaderContent2Text'>
                <span>Available Amount</span>
                <p>{userDatas[i][6]}</p>
              </section>
            </section>
            <section className='HeaderContentsBox'>

              <section className='HeaderContent2Text'>
                <span>Claimable Amount</span>
                <p>{userDatas[i][3]}</p>
              </section>
            </section>
          </div>

          <div className='TimeBox'>
            <section className='HeaderContent2Text'>
              <span>Next Vesting Time Left</span>
            </section>
            <section className='TimeContents' id="clockdiv">
              <section className='TimeContent'>
                <span class="days">{timers[i][0]}</span>
                <p>Days</p>
              </section>
              <section className='TimeContent'>
                <span class="hours">{timers[i][1]}</span>
                <p>Hours</p>
              </section>
              <section className='TimeContent'>
                <span class="minutes">{timers[i][2]}</span>
                <p>Minutes</p>
              </section>
              <section className='TimeContent'>
                <span class="seconds">{timers[i][3]}</span>
                <p>Seconds</p>
              </section>
            </section>
            {confirming === false ?
              <>
                {isConnected && chain?.id === 56 ? <>
                  <button disabled={Number(userDatas[i][3]) > 0 ? false : true} onClick={() => onTokenClaim(i + 1)} className="VestingButton">Claim</button>
                </> :
                  <></>
                }
              </>
              :
              Number(userDatas[i][3]) > 0 ?
                <div className='claimBox'>
                  <p className='Text1'>Confirming...</p>
                  {/* <ClipLoader
                    color={'#afccc6'}
                    loading={confirming}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  /> */}
                </div>
                :
                <button disabled={true} className="VestingButton">Claim</button>
            }
          </div>
        </section>
      </section>
    )
  }
  return (
    <main>
      <div className="GlobalContainer">
        {loading === false ?
          <div className="MainDashboard">
            <section className="ContactBox">
              <>
                <section className="ContractContainer">
                  <>
                    {enableClaim === true ?
                      <>
                        <section className='HeaderContent1'><img src={VestingLogo} alt="logo" width="50%" /></section>
                        <div className='HeaderBox'>
                          <section className='HeaderContent3'>
                            <button className='HeaderContent3Button' onClick={onChangeTab0} style={{ background: tab === 0 ? 'rgba(34, 255, 241, 1)' : '', color: tab === 0 ? 'black' : '' }}>Vesting Summary</button>
                            <button className='HeaderContent3Button' onClick={onChangeTab1} style={{ background: tab === 1 ? 'rgba(34, 255, 241, 1)' : '', color: tab === 1 ? 'black' : '' }}>Vesting Details</button>
                          </section>
                          {tab === 1 ?
                            <label className='checkbox'>
                              <input id="is3dCheckBox" type="checkbox" onChange={handleCheckboxChange} />
                              <p className='checkboxText'>Invested Only</p>
                            </label>
                            :
                            <></>
                          }
                        </div>
                        {
                          tab === 0 ?
                            <>
                              <section className='HeaderContentsBox'>
                                {/* <section className='HeaderContents'>
                                  <section className='HeaderContent'>
                                    <span>Total Allocation Amount</span>
                                    <p>{totalAllocationAmount}</p>
                                  </section>
                                </section> */}
                                <section className='HeaderContents'>
                                  <section className='HeaderContent'>
                                    <span>Total Claimed Amount</span>
                                    <p>{totalClaimedAmount}</p>
                                  </section>
                                </section>
                                <section className='HeaderContents'>
                                  <section className='HeaderContent'>
                                    <span>Total Available Amount</span>
                                    <p>{totalAvailableAmount}</p>
                                  </section>
                                </section>
                              </section>
                              <section className='HeaderContentsBox'>
                                <section className='HeaderContents'>
                                  <section className='HeaderContent'>
                                    <span>Total Claimable Amount</span>
                                    <p>{totalClaimableAmount}</p>
                                  </section>
                                </section>
                              </section>

                            </>
                            :
                            <>
                              <section className='HeaderContentsBox2'>
                                {isChecked ?
                                  userDatas[0][6] > 0 ?
                                    <SubContents1 i={0} />
                                    :
                                    <></>
                                  :
                                  <SubContents2 i={0} />
                                }

                                {isChecked ?
                                  userDatas[1][6] > 0 ?
                                    <SubContents1 i={1} />
                                    :
                                    <></>
                                  :
                                  <SubContents2 i={1} />
                                }

                                {isChecked ?
                                  userDatas[2][6] > 0 ?
                                    <SubContents1 i={2} />
                                    :
                                    <></>
                                  :
                                  <SubContents2 i={2} />
                                }

                              </section>
                            </>
                        }
                        {
                          <section className="LockBox">
                          </section>
                        }
                      </>
                      :
                      !isConnected ?
                        <div className='walletBox'>
                          <section className="DepositBoxHeader">
                            <img src={HeaderLogo} alt="logo" className='HeaderLogoStyle' />
                          </section>
                          <p className='walletContent'>Let us detect if you have any unclaimed by connecting to your wallet first</p>
                          <button className="VestingButton" type="submit" onClick={() => {
                            onConnect();
                          }}><img src={walletLogo} alt="logo" width={'20px'} style={{ marginRight: '5px' }} />Connect Wallet</button>
                        </div>
                        :
                        <>
                          <p class="ContractContentTextTitle">Vesting is not live yet</p>
                        </>
                    }
                  </>
                </section>
              </>
            </section>
          </div>
          :
          <>
            <div className="loadingContainer">
              <p className='Text1'>Loading...</p>
              <ClipLoader
                color={'#afccc6'}
                loading={true}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>

          </>
        }
      </div>
    </main >
  )
}

export default App
